import { lazy } from "react";
import { Link, Navigate, createBrowserRouter } from "react-router-dom";
import Genesys from "../views/Genesys.js";

/****Layouts*****/
const FullLayout = lazy(() => import("../layouts/FullLayout.js"));

/***** Pages ****/
// const Login = lazy(() => import("../layouts/Login.js"));

const Dashboard = lazy(() => import("../views/Dashboard.js"));
const Tickets = lazy(() => import("../views/Tickets.js"));
const About = lazy(() => import("../views/About.js"));
const Alerts = lazy(() => import("../views/ui/Alerts"));
const Badges = lazy(() => import("../views/ui/Badges"));
const Buttons = lazy(() => import("../views/ui/Buttons"));
const Cards = lazy(() => import("../views/ui/Cards"));
const Grid = lazy(() => import("../views/ui/Grid"));
const Charts = lazy(() => import("../views/ui/Charts.js"));
const Tables = lazy(() => import("../views/ui/Tables"));
const Forms = lazy(() => import("../views/ui/Forms"));
const Breadcrumbs = lazy(() => import("../views/ui/Breadcrumbs"));
const Accordions = lazy(() => import("../views/ui/Accordions"));
const Dropdowns = lazy(() => import("../views/ui/Dropdowns"));
const Tabs = lazy(() => import("../views/ui/Tabs"));
const Paginations = lazy(() => import("../views/ui/Paginations"));
const Modals = lazy(() => import("../views/ui/Modals"));
const Recordings = lazy(() => import("../views/Recordings.js"));

const Profile = lazy(() => import("../views/Profile.js"));

/*****Routes******/
///https://blog.logrocket.com/complete-guide-authentication-with-react-router-v6/#creating-protected-routes TIM TODO

const router = () => createBrowserRouter(
  [
    {
      // path: "/",
      // element: !isLoggedIn ? <AuthLayout /> : <Navigate to="/dashboard" />,
      // children: [
      //   { path: "login", title: "Login", exact: true, element: <Login /> },
      //   { path: "/", title: "Dashboard", element: <Navigate to="/login" /> },
      // ],
    },
    {
      path: "/",
      element: <FullLayout />,
      children: [
        { path: "/", title: "Dashboard", exact: true, element: <Navigate to="/dashboard" /> },
        { path: "dashboard", title: "Dashboard", exact: true, element: <Dashboard /> },
        { path: "tickets", title: "Tickets", exact: true, element: <Tickets /> },
        { path: "recordings", title: "Recordings", exact: true, element: <Recordings /> },
        { path: "about", title: "About", exact: true, element: <About /> },

        { path: "examples", title: "Alerts", exact: true, element: <Alerts /> },
        { path: "examples/alerts", title: "Alerts", exact: true, element: <Alerts /> },
        { path: "examples/badges", title: "Badges", exact: true, element: <Badges /> },
        { path: "examples/buttons", title: "Buttons", exact: true, element: <Buttons /> },
        { path: "examples/cards", title: "Cards", exact: true, element: <Cards /> },
        { path: "examples/grid", title: "Grids", exact: true, element: <Grid /> },
        { path: "examples/charts", title: "Charts", exact: true, element: <Charts /> },
        { path: "examples/table", title: "Tables", exact: true, element: <Tables /> },
        { path: "examples/forms", title: "Forms", exact: true, element: <Forms /> },
        { path: "examples/breadcrumbs", title: "Breadcrumbs", exact: true, element: <Breadcrumbs /> },
        { path: "examples/accordions", title: "Accordions", exact: true, element: <Accordions /> },
        { path: "examples/dropdowns", title: "Dropdowns", exact: true, element: <Dropdowns /> },
        { path: "examples/tabs", title: "Tabs", exact: true, element: <Tabs /> },
        { path: "examples/paginations", title: "Paginations", exact: true, element: <Paginations /> },
        { path: "examples/modals", title: "Modals", exact: true, element: <Modals /> },

        { path: "profile", title: "Profile", exact: true, element: <Profile /> },

        { path: "genesys", title: "Genesys", exact: true, element: <Genesys /> },
      ],
    },
    {
      path: "*",
      element: <NoMatch />,
    },
  ]
);

function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}

export default router;
