import { Card, CardBody } from "reactstrap";
import { useTheme } from "../../hooks/useTheme";

const TopCards = (props) => {

  //const theme = useTheme() === "dark-mode" ? "dark" : "light";

  //info
  const bg = "bg-" + props.bg
  const cl = "text-white " + bg

  return (
    <Card>
      <CardBody>
        <div className="d-flex">
          <div className={`circle-box lg-box d-inline-block ${cl}`}>
            <i className={props.icon}></i>
          </div>
          <div className="ms-3">
            <h3 className="mb-0 font-weight-bold">{props.value}</h3>
            <small className="text-muted">{props.subtitle}</small>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default TopCards;
