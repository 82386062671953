import Axios from "axios";
import { Auth } from "aws-amplify";
//import { getCookie } from "./utils";

//cat fact test api: https://catfact.ninja/fact

//const cat = localStorage.getItem("myCat");

export default class Api {
    constructor() {
        this.api_token = null;
        this.client = null;
        this.api_url = "http://localhost:48546/";
    }

    init = (params) => {

        //this.api_token = getCookie("ACCESS_TOKEN");
        //this.api_token = Auth.cognitoUser.signInUserSession.idToken.jwtToken;

        console.log('api_token', this.myToken());

        let headers = {
            Accept: "application/x-www-form-urlencoded",
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': 'Content-Type,Authorization',
            'Access-Control-Allow-Methods': '*',
            'Access-Control-Allow-Credentials': 'true',
            //'Authorization': 'Bearer ' + localStorage.getItem(itemkeys.find(itemkeys => itemkeys.includes("idToken")))

        };

        if (this.api_token) {
            headers.Authorization = `Bearer ${this.api_token}`;
        }

        this.client = Axios.create({
            baseURL: this.api_url,
            timeout: 31000,
            headers: headers,
        });
        

        return this.client;
    };

    getValues() {
        return this.init().get("api/values");
    }

    getUserList = (params) => {
        return this.init().get("/users", { params: params });
    };

    myToken() {
        return new Promise((resolve) => {
            resolve(
                Auth.currentSession().then(res => {
                    let accessToken = res.getAccessToken()
                    let jwt = accessToken.getJwtToken()

                    //You can print them to see the full objects
                    console.log(`myAccessToken: ${JSON.stringify(accessToken)}`)
                    console.log(`myJwt: ${jwt}`)
                    this.api_token = jwt;
                })
            );
        });
    }

}
