import React, { useState, useEffect } from 'react';
import { Row, Col, CardTitle, Button, CardSubtitle, Card, CardBody, Table, Badge, Label, Input, Form, Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import ComponentCard from '../components/ComponentCard';
import TopCards from '../components/dashboard/TopCards';
import { useTheme } from '../hooks/useTheme';
import Api from '../services/api';

const Genesys = () => {
  const api = new Api();


  const fetchValues = () => {
    api
      .getValues()
      .then((response) => {
      console.log('response: ', response)
      console.log('response data: ', response.data)
      setData(response.data)

      })
      .catch((err) => console.log(err));
  };

  const [data, setData] = useState([])

  useEffect(() => {
    fetchValues();
  }, []);

  const [state, setState] = useState(true);
  const theme = useTheme();
  const tableData = [
    {
      Id: 1,
      Ticket: "Sweet pie topping caramels tiramisu bonbon muffin pastry.",
      Status: "open",
      User: "Tim Canga",
      Date: "20-07-2023",
    },
    {
      Id: 2,
      Ticket: "Cake gummies gummi bears apple pie sweet.",
      Status: "closed",
      User: "Tim Canga",
      Date: "10-06-2023",
    },
    {
      Id: 3,
      Ticket: "Jelly cupcake caramels sugar plum danish.",
      Status: "pending",
      User: "Tim Canga",
      Date: "15-07-2023",
    },
    {
      Id: 4,
      Ticket: "Cotton candy croissant halvah pudding caramels cake halvah.",
      Status: "deleted",
      User: "Tim Canga",
      Date: "17-05-2022",
    },
  ];
  return (
    <>
      <Row>
        <Col>
          <Card>
            <CardBody>
              <CardTitle tag="h5" className="mb-3">Queues</CardTitle>
              <p>{data}</p>

              {/* <Row>
                <Col sm="12" lg="6" className="mb-3 align-items-center">
                  <Form>
                    <Label className="visually-hidden" for="exampleSearch">Search</Label>
                    <Input
                      id="exampleSearch"
                      name="search"
                      placeholder="Search Ticket..."
                      type="search"
                    />
                  </Form>
                </Col>
                <Col sm="12" lg="6">
                  <Button className=" btn-icon btn-md" color="primary">Advanced Search</Button>
                  <Button className="btn-icon btn-md float-end" color="primary">Create New</Button>
                </Col>
              </Row> */}
              {/* <Table hover className={theme === "dark-mode" ? "no-wrap mt-3 align-middle table-dark" : "no-wrap mt-3 align-middle"} responsive borderless >
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Ticket</th>
                    <th>Status</th>
                    <th>User</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                  {tableData.map((tdata, index) => (
                    <tr key={index} className="border-top">
                      <td>{tdata.Id}</td>
                      <td>{tdata.Ticket}</td>
                      <td>
                        <Badge color={statusColor(tdata.Status)} pill className='w-100'>
                          {tdata.Status}
                        </Badge>
                      </td>
                      <td>{tdata.User}</td>
                      <td>{tdata.Date}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <Pagination aria-label="Page navigation example" className="float-end info">
                <PaginationItem>
                  <PaginationLink
                    first
                    href=""
                  />
                </PaginationItem>
                <PaginationItem>
                  <PaginationLink previous href="" />
                </PaginationItem>
                <PaginationItem active>
                  <PaginationLink href="">
                    1
                  </PaginationLink>
                </PaginationItem>
                <PaginationItem>
                  <PaginationLink href="">
                    2
                  </PaginationLink>
                </PaginationItem>
                <PaginationItem>
                  <PaginationLink href="">
                    3
                  </PaginationLink>
                </PaginationItem>
                <PaginationItem>
                  <PaginationLink href="">
                    4
                  </PaginationLink>
                </PaginationItem>
                <PaginationItem>
                  <PaginationLink href="">
                    5
                  </PaginationLink>
                </PaginationItem>
                <PaginationItem>
                  <PaginationLink next href="" />
                </PaginationItem>
                <PaginationItem>
                  <PaginationLink
                    href=""
                    last
                  />
                </PaginationItem>
              </Pagination> */}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Genesys;
