import { RouterProvider } from 'react-router-dom';
import routes from "./routes/Router";
import { useTheme } from "./hooks/useTheme";
import { useState } from "react";
import { Col, Container, Row } from 'reactstrap';

//Authenticator
import { Amplify } from 'aws-amplify';
import { Authenticator, Image, View } from '@aws-amplify/ui-react';
//import '@aws-amplify/ui-react/styles.css';
import './assets/scss/amplifyoveride.scss'
import awsExports from './aws-exports';
import Logo from "./layouts/Logo";

Amplify.configure(awsExports);

export default function App() {

  //const [isLoggedIn] = useState(true);

  //const [cognitoUser] = useState({})


  const routing = routes();

  const theme = useTheme();

  const components = {
    Header() {
      //const { tokens } = useTheme();

      return (

        <div className="my-5">
          <Logo className="mt-5"></Logo>
        </div>

      );
    },
  }

  return (

    <div className={theme}>

      {/* <Authenticator components={components} loginMechanisms={['email']}>
        {({ signOut, user }) => ( */}
          <main>
            {/* <h1>Hello {user.username}</h1>
            <button onClick={signOut}>Sign out</button> */}

            <RouterProvider router={routing} />
          </main>
        {/* )}
      </Authenticator> */}



    </div>




  );
};
